import React, { useContext} from 'react'
import {
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Box } from '@mui/system'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Hider from '../utils/Hider'

export default function CreditCardList({}) {
  const { gStripe } = useContext(GlobalStateContext)






  return (
    <Box>
      <List disablePadding>
        {/* {JSON.stringify(gStripe.subscription.id)} */}
        {gStripe.cardList.length > 0 &&
            gStripe.cardList.map((row, index) => (
            <ListItemButton
              key={row.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '350px',
              }}
              onClick={(event) => {
                event.preventDefault()
                gStripe.changeDefaultPay(row.id)

              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemText
                  sx={{
                    opacity:
                      row.isDefaultPay
                        ? '1'
                        : '.5',
                  }}
                >
                  {row.brand} - {row.last4}
                </ListItemText>
                <ListItemText
                  disableTypography
                  primary={
                    <Hider
                      show={ row.isDefaultPay}
                    >
                      <Typography
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#68C6C8',
                          fontSize: '12px',
                          borderRadius: '3px',
                          padding: '2px',
                          marginLeft: '5px',
                        }}
                      >
                        デフォルト
                      </Typography>
                    </Hider>
                  }
                />
              </Box>
              <Hider show={gStripe.cardList.length > 1}>
                <ListItemIcon
                  onClick={(event) => {
                    event.stopPropagation()
                    gStripe.delCard(row.id)

                  }}
                >
                  <DeleteOutlineIcon
                    sx={{
                      color: 'black',
                      opacity:
                          gStripe.cardList
                          ? '1'
                          : '.5',
                      '&:hover': {
                        color: '#CC4A44',
                        opacity: 1,
                      },
                    }}
                  />
                </ListItemIcon>
              </Hider>
            </ListItemButton>
          ))}
      </List>
    </Box>
  )
}
