import React, {useState, useContext,  useEffect} from 'react'
import {Box,  Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import Layout from "../layouts";
import {CmPay} from "../components/cfp/CmPay";
import CreditCardList from "../components/setting/creditCardList";
import {GlobalStateContext} from "../context/GlobalContextProvider";
import {CmFeeLevel} from "../components/setting/CmFeeLevel";
import EditCompanyName from "../components/setting/editCompanyName";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {CmLevelUpdate} from "../components/cfp/cmLevelUpdate";
import {gUtils} from "../components/utils/gutils";
import CmCheckBiz from "../components/cmcheckbiz";

const CmTextItem = ({leftItem, rightItem, callSubmitName, mEdit = false,isShowSetting}) => {


    const [rightText,setRightText]=useState('')
    useEffect(()=>{
        setRightText(rightItem)

    },[rightItem])
    const [isShowRight, setIsShowRight] = useState(true)
    return <>
        <Box sx={{display: 'flex', mt: '20px'}}>
            <Typography
                variant="h3"
                sx={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                    fontSize: '16px',
                    fontWeight: 600,
                    width: '400px',
                    lineHeight: '22px',
                    letterSpacing: '0.06em',
                    textAlign: 'left',
                    color: '#333333'
                }}
            >
                {leftItem}
            </Typography>


            {isShowRight && <Typography
                variant="h3"
                sx={{
                    fontFamily: "'Noto Sans JP', sans-serif",
                    fontSize: '16px',
                    marginLeft: '20px',
                    fontWeight: 400, // Normal weight
                    lineHeight: '22px',
                    letterSpacing: '0.06em',
                    textAlign: 'left',
                    color: '#333333',
                    whiteSpace: 'pre'

                }}
            >
                {rightText}
            </Typography>}


            {mEdit && <EditCompanyName
                companyName={rightText}
                isShowSetting={isShowSetting}
                setCompanyName={setRightText}
                callEdit={(f) => {
                    setIsShowRight(!f)
                }}
                submitName={()=>{

                    callSubmitName(rightText)
                }}
            />}


        </Box>

    </>
}
export default function Settings({location}) {


    const {gStripe, setCustomModalOpen, setModalContent} = useContext(GlobalStateContext)
    const [level,setLevel]=useState(0)
    const [price,setPrice]=useState('00')
    const [date,setDate]=useState('')
    const [formattedPrice,setFormattedPrice]=useState('')
    const [isShowSetting,setIsShowSetting]=useState(false)
    useEffect(()=>{
        setIsShowSetting(gStripe.isOwer)

    },[gStripe.isOwer])
    useEffect(() => {
       // console.log("priceprice",price)

        setFormattedPrice(new Intl.NumberFormat("ja-JP").format(price))
    }, [price]);

    const openCmPay = () => {
        setCustomModalOpen(true)
        setModalContent(
        <CmPay
            gStripe={gStripe}
            submit={() => {
                setCustomModalOpen(false)
            }}
        />
        )
    }



    useEffect(()=>{

       // console.log("当前levelinfo",gStripe.levelInfo)

        if (gStripe.levelInfo) {

            setLevel(gStripe.levelInfo.level);

            setDate(gUtils.timeToDate(gStripe.levelInfo.time));

            const price = gStripe.levelInfo.total
            setPrice(price)
        }



    },[gStripe.levelInfo])
    const showOldBiz = async () => {

        setModalContent(
            <CmCheckBiz

                setCustomModalOpen={setCustomModalOpen}
                callSure={()=>{

                    gStripe.getApiLevelInfo(undefined,true)
                }}

            />
        )
        setCustomModalOpen(true)

    };
    const callUpdate=async (chooseLevel,isCancel)=>{

        if (!gStripe.isNewBiz){
            await showOldBiz()

            return

        }


        if (isCancel){
            gStripe.updateLevel(0,false,true)
        }else {
            if (gStripe.cardList.length > 0 ){
                setCustomModalOpen(true)
                setModalContent(
                    <CmLevelUpdate
                        levelConfig={gStripe.levelConfig}
                        clickCancel={()=>{
                            setCustomModalOpen(false)
                        }}
                        clickSure={(nlevel,isYear)=>{
                            //  if (isYear)
                            setCustomModalOpen(false)
                            let   c=isYear?1:0
                            let  plevel=Math.floor(nlevel*2+c)
                            let  isUpgrade=level*2<plevel
                        //    console.log("levelInfo", plevel,nlevel,isYear,level,isUpgrade)

                            gStripe.updateLevel(plevel,isUpgrade)
                        }}

                        chooseLevel={chooseLevel}
                        curLevel={level}

                    />
                )
            }else {
                openCmPay()
            }
        }





        //
    }


    const getLevel = (level) => {

        const levelName = ['Free', 'Light', 'Pro', 'Business', 'Enterprise']

        return levelName[level]
    }

    const getNextLevelTitle=(gStripe)=>{
        if (gStripe.customer.isRootUser){
          return '無制限プラン'
        }
        let  info=gStripe.levelInfo
        if (!info){
            return  getLevel(0)
        }

        const paymentMethod = info.isYear ? "年額プラン" : "月額プラン";

        if (info.nextEndDate){
          //  console.log("info.nextPrice", info.nextPrice)
            let  date=new Date(parseInt(info.nextEndDate)*1000)
            let  time=date.getFullYear()+"年"+(date.getMonth()+1)+"月"+date.getDate()+"日"
            let  myear=info.nextPrice%2===1?"年額プラン":"月額プラン"
            return  `${getLevel(info.level)} (${paymentMethod})        ※${time} ${getLevel(Math.floor(info.nextPrice/2))}(${myear})にダウングレードします。`
        }else {
            return `${getLevel(info.level || 0)} (${paymentMethod})`
        }

    }

    return <>
        <Layout location={location}>
            <Card>
                <CardHeader
                    title={
                        <Typography variant="h2" sx={{ml: "9px"}}>
                            設定
                        </Typography>
                    }
                />
                <CardContent
                    sx={{
                        mt: 0,
                        p: "0px",
                    }}
                >
                    <Card>

                        <CmTextItem leftItem={'会社名'} rightItem={gStripe.customer.name} mEdit={true} callSubmitName={(item)=>{
                            gStripe.changeName(item)
                        }}  isShowSetting={isShowSetting}/>
                        <CmTextItem leftItem={'現在ご契約中のプラン'} rightItem={getNextLevelTitle(gStripe)}/>
                        <CmTextItem leftItem={'次のお支払い日'} rightItem={date}/>
                        <CmTextItem leftItem={'次のお支払い金額'} rightItem={`${formattedPrice}円(税込)`}/>


                        {isShowSetting&&
                            <Box >
                            <Box sx={{display: 'flex', marginTop: '36px'}}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontFamily: 'Noto Sans',
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        marginTop: '5px',
                                        lineHeight: '27px',
                                        letterSpacing: '0.06em',
                                        textAlign: 'left',
                                        color: '#333333'
                                    }}
                                >
                                    {'お支払い情報'}
                                </Typography>
                                <IconButton
                                    sx={{ml: '10px'}}
                                    onClick={openCmPay}
                                >
                                    <AddCircleIcon style={{color: "#28AEB1"}}/>
                                </IconButton>
                            </Box>
                            <CreditCardList/>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontFamily: 'Noto Sans',
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    marginTop: '36px',
                                    lineHeight: '27px',
                                    letterSpacing: '0.06em',
                                    textAlign: 'left',
                                    color: '#333333'
                                }}
                            >
                                {'プラン'}
                            </Typography>
                            <CmFeeLevel  callUpdate={callUpdate} nextPrice={gStripe.levelInfo?.nextPrice} levleInfo={gStripe.levelInfo}/>
                            <Typography sx={{ marginLeft: "8px" }}>
                                ※プランの表示価格はすべて年額プランの月換算した金額（税抜き）です。
                            </Typography>

                        </Box>}

                    </Card>

                </CardContent>
            </Card>


        </Layout>


    </>
}
