import React, { useState, useContext, useEffect } from 'react'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Hider from '../utils/Hider'
import { Box, IconButton, TextField } from '@mui/material'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

export default function EditCompanyName({ companyName, setCompanyName ,callEdit,submitName,isShowSetting=true}) {
  const [edit, setEdit] = useState(false)
  const { gStripe } = useContext(GlobalStateContext)
  useEffect(() => {
    if (gStripe.customer.id) {
      setEdit(false)
    }
  }, [gStripe.customer.id])
 useEffect(()=>{
     if (callEdit)  callEdit(edit)

 },[edit])

  return (
    <Hider show={edit}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          size="small"
          defaultValue={companyName}
          disabled={false}
          onChange={(e) => {
            setCompanyName(e.target.value)
          }}
        />
        <IconButton
          size="small"
          onClick={()=>{
              setEdit(false)
              submitName()
          }}

        >
          <CheckCircleOutlineRoundedIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setCompanyName(gStripe.customer.name)
            setEdit(false)
          }}
        >
          <CancelOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box
        sx={{
          marginTop: "-3px",
          marginLeft: "2px"
        }}
      >

          {isShowSetting
          &&     <IconButton
                  size="small"
                  onClick={() => {
                      setEdit(true)
                  }}

              >
                  <EditRoundedIcon sx={{ color: 'primary.main', fontSize: '14px' }} />
              </IconButton>
          }

      </Box>
    </Hider>
  )
}
